import { jwtDecode } from "jwt-decode"
import { getDoctorProfile } from "../services/http-service/api"

export const getGender = (patient) => {
    return patient &&
    patient.profile &&
    (/^male$/i).test(patient.profile.data.gender)
    ? "Мужской"
    : patient &&
      patient.profile &&
      (/^female$/i).test(patient.profile.data.gender) ? "Женский" : ""
}

export const getUserFromToken = async (storedToken) => {
    const tokenData = storedToken && storedToken.split(" ", 2)
    let auth
    if (tokenData && tokenData.length == 2) {
        const token = jwtDecode(tokenData[1])
        if (token.role === 2) {
            auth = await getDoctorProfile(token, tokenData)
        } else {
            // root login
            auth = { ...token, token: tokenData[1], id: token.uid, isAdmin: token.role === 0 }
        }
    }
    return auth
}

export const examStatus = (exam) => {
    let status = exam.profile?.data?.external_id ? "DAMUMED": exam.status
    switch (status) {
        case 'OPEN':
            return exam.byDoctor ? "Назначено" : "Направлено доктору"
        case 'DAMUMED':
            return 'Направлено в Damumed'
        case 'CLOSED':
            return 'Получен'
        case 'ACTIVE':
            return 'Выполняется'
        case 'INACTIVE':
            return 'Пройдено'
        case 'UPLOADING':
            return 'В процессе выгрузки'
    }
}

export const aiAllowed = (examType) => {
    return examType === 3
}

export const aiStatus = (point) => {
    const pointData = JSON.parse(point && point.media?.profile?.data?.external_data || "{}")
    console.log(pointData)
    const name = pointData.data && pointData.data.length > 0 && pointData.data[0].result.aiResult.probParams.class
        && pointData.data[0].result.aiResult.probParams.class.length > 0 ?
        pointData.data[0].result.aiResult.probParams.class[0].name : ""
    if (name === 'bad_endo') {
        return "AI заключение: не является эндоскопией уха"
    }
    if (name === 'nose') {
      return 'AI заключение: Риноскопия'
    }
    if (name === 'normal') {
      return 'AI заключение: 🟢 Нет патологии'
    }
    if (name === 'pathology') {
      return 'AI заключение: 🔴 Патология'
    }
    return `AI-заключение: ${name || 'не получено'}`
}
